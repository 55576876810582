import React, { Component } from "react";
import {
    Button,
    Col,
    Icon,
    message,
    Row,
    Skeleton,
    Table,
} from "antd";
import DefaultAvatar from "../../../assets/images/unisex_avatar_icon.png";
import Column from "antd/lib/table/Column";
import {
    adminResponseRequest,
    disburseLoan,
    getLoanRequest,
} from "../../../APIsHandler/ApiController";
import moment from "moment";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import NavigationTab from "../../NavigationTab";
import { Redirect } from "react-router-dom";
import LoanRequestDetailsModal from "./LoanRequestDetailsModal";

export default class LoanRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
            todayDate: new Date(),
            selectedRowKeys: [],
            loading: true,
            usersLoans: [],
            viewDetails: false,
            currentIndex: 0,
            declineRequest: false,
            addRequest: false,
            currentNavIndex: 0,
            nextNavIndex: 10,
            navDiff: 10,
            noOfPages: 0,
            currentPage: 1,
            disburse: false,
            currentUser: "user",
            redirectToLoanSchedules: false,
            currentLoanRequest: "",
            loan: null,
        };
    }

    componentDidMount() {
        getLoanRequest()
            .then((res) => {
                let { data } = res.data;
                let num = Math.ceil(data.length / this.state.navDiff);
                this.setState({
                    usersLoans: data,
                    noOfPages: num,
                    loading: false,
                });
            })
            .catch((err) => {
                message.error("Unable to load page", 3);
                this.setState({ loading: false });
            });
    }

    onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    onSelectChange = (selectedRowKeys) => {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    //closes details modal
    closeModal = (e) => {
        this.setState({ addDetails: false, viewDetails: false });
    };

    //shows details modal
    showModal = (e, index) => {
        this.setState({
            currentIndex: index,
            viewDetails: true,
            loan: this.state.usersLoans[index],
        });
    };

    displayPaybackSchedule = (event, loanRequestId) => {
        event.preventDefault();
        this.setState({
            redirectToLoanSchedules: true,
            currentLoanRequest: loanRequestId,
        });
    };

    /*
     * Accept request*/
    acceptRequest = (id) => {
        this.setState({ addRequest: true });
        adminResponseRequest(
            {
                loan_request_id: id,
                status: "approved",
                comment: "Approved",
            },
            this.onAddDatails
        );
    };

    onAddDatails = (status, data) => {
        if (status) {
            this.setState({ addRequest: false });
            message.success("The request has been accepted!");
            window.location.reload();
        } else {
            console.log(data);
            message.error("Unable to accept request! " + data.data.message);
            this.setState({ addRequest: false });
        }
    };

    /*
     * Accept request*/
    declineLoan = (id) => {
        // const { usersLoans, currentIndex } = this.state;

        this.setState({ declineRequest: true });
        adminResponseRequest(
            {
                loan_request_id: id,
                status: "declined",
                comment: "Declined",
            },
            this.onDeclineDatails
        );
    };

    onDeclineDatails = (status, data) => {
        if (status) {
            console.log(data);
            this.setState({ declineRequest: false });
            message.success("The request has been declined!");
            window.location.reload();
        } else {
            console.log(data);
            message.error("Unable to decline request! " + data.data.message);
            this.setState({ declineRequest: false });
        }
    };

    navigateRight = () => {
        const {
            navDiff,
            nextNavIndex,
            currentNavIndex,
            currentPage,
            noOfPages,
        } = this.state;

        if (currentPage !== noOfPages) {
            this.setState({
                nextNavIndex: nextNavIndex + navDiff,
                currentNavIndex: currentNavIndex + navDiff,
                currentPage: (nextNavIndex + navDiff) / navDiff,
            });
        }
    };

    navigateLeft = () => {
        const { navDiff, nextNavIndex, currentNavIndex, currentPage } =
            this.state;

        if (currentPage !== 1) {
            this.setState({
                nextNavIndex: nextNavIndex - navDiff,
                currentNavIndex: currentNavIndex - navDiff,
                currentPage: (nextNavIndex - navDiff) / navDiff,
            });
        }
    };

    /*disburse loan into user wallet*/

    transferFund = (id, loanPackageName, LoanPackageAmount) => {
        this.setState({ disburse: true });

        const params = {
            loan_request_id: id,
            status: "success",
            type: "credit",
            description: `Credit wallet for ${
                loanPackageName
            } loan of ${naira} ${formatCurrency(
                LoanPackageAmount
            )}.`,
        };
        disburseLoan(params, this.onDisburse);
    };

    onDisburse = (status, data) => {
        if (status) {
            this.setState({ disburse: false });
            message.success("Money has been disbursed into user wallet!", 5);
            window.location.reload();
        } else {
            message.error("Unable to disburse fund.");
        }
    };

    //switch between user and guarantor
    setUser = (name) => {
        this.setState({ currentUser: name });
    };

    render() {
        const {
            selectedRowKeys,
            usersLoans,
            loading,
            viewDetails,
            currentIndex,
            declineRequest,
            addRequest,
            currentNavIndex,
            nextNavIndex,
            navDiff,
            currentPage,
            disburse,
            currentUser,
            currentLoanRequest,
            redirectToLoanSchedules,
        } = this.state;
        console.log("state", this.state);

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        if (redirectToLoanSchedules) {
            return (
                <Redirect
                    to={`/dashboard/admin/loan-schedules/${currentLoanRequest}`}
                />
            );
        }
        return (
            <>
                <h1 className="z-dashboard-label">Loan Requests</h1>
                <Skeleton loading={loading} active>
                    <div>
                        <Row>
                            <Col span={24}>
                                <div
                                    className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                                    style={{ height: "100%" }}
                                >
                                    <div className="z-flex-start z-mb-6">
                                        <Icon
                                            type="copy"
                                            className="z-label-icon"
                                        />
                                        <p className="z-label-text">
                                            Loan Requests
                                        </p>
                                    </div>
                                    <div>
                                        <Table
                                            rowSelection={rowSelection}
                                            bordered={false}
                                            dataSource={usersLoans.slice(
                                                currentNavIndex,
                                                nextNavIndex
                                            )}
                                            className="z-admin-table z-todo"
                                            pagination={false}
                                            rowKey="id"
                                            rowClassName={(record, index) => {
                                                if (index % 2 === 0) {
                                                    return "z-stripped";
                                                }
                                            }}
                                            scroll={{ x: "220%" }}
                                        >
                                            <Column
                                                title="Mark all"
                                                dataIndex="sn"
                                                key="sn"
                                                render={(
                                                    text,
                                                    record,
                                                    index
                                                ) => {
                                                    return (
                                                        <div className="">
                                                            <p className="z-table-num z-no-mb">
                                                                {Number(
                                                                    index +
                                                                        (currentPage -
                                                                            1) *
                                                                            navDiff
                                                                ) + 1}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Loan Request ID"
                                                dataIndex="loan_id"
                                                key="loan_id"
                                                render={(
                                                    text,
                                                    record,
                                                    index
                                                ) => {
                                                    return (
                                                        <div className="">
                                                            <p className="z-table-num z-no-mb">
                                                                {record.id}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Name"
                                                dataIndex="name"
                                                key="name"
                                                render={(text, record) => {
                                                    return (
                                                        <div className="z-flex-space">
                                                            <div className="z-more">
                                                                <Icon
                                                                    type="more"
                                                                    className="z-font-12"
                                                                />
                                                            </div>
                                                            <div className="z-unisex-avatar z-table-avatar">
                                                                <img
                                                                    src={
                                                                        record
                                                                            .user
                                                                            .user_profile
                                                                            ? record
                                                                                  .user
                                                                                  .user_profile
                                                                                  .avatar
                                                                                ? record
                                                                                      .user
                                                                                      .user_profile
                                                                                      .avatar
                                                                                : DefaultAvatar
                                                                            : DefaultAvatar
                                                                    }
                                                                    alt="logo"
                                                                />
                                                            </div>
                                                            <div>
                                                                <p className="z-table-name z-table-parag">
                                                                    {
                                                                        record
                                                                            .user
                                                                            .full_name
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Status"
                                                dataIndex="status"
                                                key="status"
                                                render={(text, record) => {
                                                    return (
                                                        <div>
                                                            <p
                                                                className={
                                                                    record.status ===
                                                                    "approved"
                                                                        ? "z-table-status z-table-parag2 z-color-green2"
                                                                        : record.status ===
                                                                          "pending"
                                                                        ? "z-table-status z-table-parag2 z-color-yellow"
                                                                        : "z-table-status z-table-parag2"
                                                                }
                                                            >
                                                                {record.status}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Loan Disbursed"
                                                dataIndex="loan_disbursed"
                                                key="loan_disbursed"
                                                render={(text, record) => {
                                                    return (
                                                        <div>
                                                            <p
                                                                className={
                                                                    record.loan_disbursed ===
                                                                    1
                                                                        ? "z-table-status z-table-parag2 z-color-green2"
                                                                        : record.loan_disbursed ===
                                                                          0
                                                                        ? "z-table-status z-table-parag2 z-color-yellow"
                                                                        : "z-table-status z-table-parag2"
                                                                }
                                                            >
                                                                {record.loan_disbursed ===
                                                                1
                                                                    ? "disbursed"
                                                                    : "not yet disbursed"}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Guarantor"
                                                dataIndex="guarantor"
                                                key="guarantor"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag">
                                                            {record.request_guarantor
                                                                ? record
                                                                      .request_guarantor
                                                                      .guarantor_email
                                                                : "null"}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Guarantor Approval Status"
                                                dataIndex="guarantor_approval_status"
                                                key="guarantor_approval_status"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag">
                                                            {record.request_guarantor
                                                                ? record
                                                                      .request_guarantor
                                                                      .guarantor_approved
                                                                : "null"}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Type of Funding"
                                                dataIndex="type_funding"
                                                key="type_funding"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag">
                                                            {record.request_guarantor &&
                                                            record
                                                                .request_guarantor
                                                                .guarantor_approved ===
                                                                "approved"
                                                                ? record.guarantor_cash_down !==
                                                                  null
                                                                    ? "Cash down payment"
                                                                    : "Admin funding"
                                                                : "Not yet determined"}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Date Applied"
                                                dataIndex="applied"
                                                key="applied"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text2 z-table-parag2">
                                                            {moment(
                                                                record.created_at
                                                            ).format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Amount"
                                                dataIndex="amount"
                                                key="amount"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag3">
                                                            {naira}{" "}
                                                            {formatCurrency(
                                                                record.amount ||
                                                                    record
                                                                        .loan_package
                                                                        .amount
                                                            )}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title=""
                                                dataIndex="action"
                                                key="Action"
                                                render={(i, record, index) => {
                                                    return (
                                                        <div className="">
                                                            <Button
                                                                className="z-tablebtn"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.showModal(
                                                                        e,
                                                                        index +
                                                                            (currentPage -
                                                                                1) *
                                                                                navDiff
                                                                    );
                                                                }}
                                                            >
                                                                {" "}
                                                                view
                                                            </Button>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title=""
                                                dataIndex="payback"
                                                key="payback"
                                                render={(i, record, index) => {
                                                    if (
                                                        record.status ===
                                                        "approved"
                                                    ) {
                                                        return (
                                                            <div className="">
                                                                <Button
                                                                    className="z-tablebtn"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.displayPaybackSchedule(
                                                                            e,
                                                                            record.id
                                                                        )
                                                                    }
                                                                >
                                                                    Payback
                                                                    Schedules
                                                                </Button>
                                                            </div>
                                                        );
                                                    }
                                                    {
                                                        return "";
                                                    }
                                                }}
                                            />
                                        </Table>
                                    </div>
                                    {usersLoans.length > 0 && (
                                        <div className="z-admin-gr-div">
                                            <NavigationTab
                                                noOfPages={this.state.noOfPages}
                                                currentPage={
                                                    this.state.currentPage
                                                }
                                                navigateLeft={this.navigateLeft}
                                                navigateRight={
                                                    this.navigateRight
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Skeleton>
                <LoanRequestDetailsModal
                    loanRequest={this.state.loan}
                    closeModal={this.closeModal}
                    viewDetails={viewDetails}
                    declineLoan={this.declineLoan}
                    declineRequest={this.state.declineRequest}
                    acceptRequest={this.acceptRequest}
                    addRequest={this.state.addRequest}
                    transferFund={this.transferFund}
                    disburse={this.state.disburse}
                />
            </>
        );
    }
}
