import React, { useEffect, useState } from "react";
import { Button, Icon, Modal, Popconfirm, Row } from "antd";
import { REPAYMENT_OPTIONS } from "../../../constants";
import moment from "moment";
import DefaultAvatar from "../../../assets/images/unisex_avatar_icon.png";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";

const MODAL_TABS = ["user", "user_bvn", "guarantor"];
const TAB_TITLE_MAP = {
    user: "User Profile",
    user_bvn: "User BVN",
    guarantor: "Guarantor Profile",
};

export default function LoanRequestDetailsModal({
    loanRequest,
    closeModal,
    viewDetails,
    declineLoan,
    declineRequest,
    acceptRequest,
    addRequest,
    transferFund,
    disburse,
}) {
    const [loanPackage, setLoanPackage] = useState(null);
    const [loanPackageName, setLoanPackageName] = useState(null);
    const [loanPackageAmount, setLoanPackageAmount] = useState(null);
    const [tab, setTab] = useState("user");
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [userProfileAvatar, setUserProfileAvatar] = useState(null);
    const [requestGuarantor, setRequestGuarantor] = useState(null);
    const [requestGuarantorUser, setRequestGuarantorUser] = useState(null);
    const [requestGuarantorProfile, setRequestGuarantorProfile] =
        useState(null);
    const [requestGuarantorProfileAvatar, setRequestGuarantorProfileAvatar] =
        useState(null);
    const [repaymentOptionStatus, setRepaymentOptionStatus] = useState(null);
    const [identityVerification, setIdentityVerification] = useState(null);

    useEffect(() => {
        if (loanRequest) {
            setLoanPackage(loanRequest.loan_package);
            setLoanPackageAmount(loanRequest.loan_package.amount);
            setLoanPackageName(
                loanRequest.amount || loanRequest.loan_package.name
            );
            setUser(loanRequest.user);
            setCompany(loanRequest.user ? loanRequest.user.company : null);
            setRequestGuarantor(loanRequest.request_guarantor);
            setRepaymentOptionStatus(
                loanRequest.repayment_option === REPAYMENT_OPTIONS.BANK
                    ? loanRequest.mandate.status
                    : "Approved"
            );
        }
    }, [loanRequest]);

    useEffect(() => {
        if (user) {
            setIdentityVerification(user.identity_verification_log);
            setUserProfile(user.user_profile);
        }
    }, [user]);

    useEffect(() => {
        if (userProfile) {
            setUserProfileAvatar(userProfile.avatar);
        }
    }, [userProfile]);

    useEffect(() => {
        if (requestGuarantor) {
            setRequestGuarantorUser(requestGuarantor.user);
        }
    }, [requestGuarantor]);

    useEffect(() => {
        if (requestGuarantorUser) {
            setRequestGuarantorProfile(requestGuarantorUser.user_profile);
        }
    }, [requestGuarantorUser]);

    useEffect(() => {
        if (requestGuarantorProfile) {
            setRequestGuarantorProfileAvatar(requestGuarantorProfile.avatar);
        }
    }, [requestGuarantorProfile]);

    const moveTabLeft = () => {
        const i = MODAL_TABS.indexOf(tab);

        if (i === 0) {
            setTab(MODAL_TABS[MODAL_TABS.length - 1]);
        } else {
            setTab(MODAL_TABS[i - 1]);
        }
    };

    const moveTabRight = () => {
        const i = MODAL_TABS.indexOf(tab);

        if (i === MODAL_TABS.length - 1) {
            setTab(MODAL_TABS[0]);
        } else {
            setTab(MODAL_TABS[i + 1]);
        }
    };

    return (
        <Modal
            className="z-custom z-custom2 "
            visible={viewDetails}
            title={"w"}
            onOk={() => {}}
            onCancel={closeModal}
            footer={[
                loanRequest && loanRequest.status === "pending" ? (
                    <div className="z-flex-row" key="confirmLoan">
                        <Popconfirm
                            title="Are you sure you want to decline the loan request?"
                            onConfirm={() =>
                                declineLoan(loanRequest ? loanRequest.id : null)
                            }
                            onCancel={() => {}}
                            okText="Decline"
                            cancelText="No"
                            key="1"
                        >
                            <Button
                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row"
                                shape="round"
                                size="large"
                                key="3"
                                loading={declineRequest}
                            >
                                Decline
                                <Icon type="close" />
                            </Button>
                        </Popconfirm>
                        ,
                        <Popconfirm
                            title="Are you sure you want to accept the loan request?"
                            onConfirm={() =>
                                acceptRequest(
                                    loanRequest ? loanRequest.id : null
                                )
                            }
                            onCancel={() => {}}
                            okText="Accept"
                            cancelText="No"
                            key="20"
                        >
                            <Button
                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                shape="round"
                                size="large"
                                key="23"
                                loading={addRequest}
                            >
                                Accept
                                <Icon type="check" />
                            </Button>{" "}
                        </Popconfirm>
                    </div>
                ) : loanRequest && loanRequest.status === "declined" ? (
                    <p className="z-declined">Declined</p>
                ) : loanRequest &&
                  loanRequest.status === "approved" &&
                  loanRequest.payment_status !== "unpaid" ? (
                    <p className="z-accepted">Accepted and Disbursed</p>
                ) : loanRequest &&
                  loanRequest.status === "approved" &&
                  loanRequest.loan_disbursed === 0 ? (
                    <Popconfirm
                        title="Are you sure you want to disburse into the user wallet?"
                        onConfirm={() =>
                            transferFund(
                                loanRequest ? loanPackage.id : null,
                                loanPackageName,
                                loanPackageAmount
                            )
                        }
                        onCancel={() => {}}
                        okText="Disburse"
                        cancelText="No"
                        key="22"
                    >
                        <Button
                            className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                            shape="round"
                            size="large"
                            key="21"
                            loading={disburse}
                        >
                            Disburse Loan
                            <Icon type="check" />
                        </Button>{" "}
                    </Popconfirm>
                ) : (
                    loanPackage > 0 &&
                    loanPackage.status === "cancelled" && (
                        <p className="z-accepted">Cancelled</p>
                    )
                ),
            ]}
        >
            <div>
                <div className="z-flex-space z-nav-holder z-modal-nav">
                    <div
                        className={
                            "z-not-left  z-flex-column-center z-modal-b z-modal-buser"
                        }
                        onClick={moveTabLeft}
                    >
                        <Icon type="left" />
                    </div>
                    <div className="z-flex-space z-width-35 z-nav-holder-p z-muser">
                        {TAB_TITLE_MAP[tab]}
                    </div>
                    <div
                        className={
                            "z-not-left z-flex-column-center z-modal-b z-modal-buser"
                        }
                        onClick={moveTabRight}
                    >
                        <Icon type="right" />
                    </div>
                </div>
                <Row>
                    <div className="z-flex-space z-p-3">
                        <div className="z-testimonial-avatar z-profile-avatar z-mb-1 z-img-100">
                            {tab === "user" && (
                                <img
                                    src={
                                        userProfileAvatar
                                            ? userProfileAvatar
                                            : DefaultAvatar
                                    }
                                    alt="avatar"
                                />
                            )}
                            {tab === "user_bvn" && identityVerification ? (
                                <img
                                    src={
                                        identityVerification.photo
                                            ? identityVerification.photo
                                            : DefaultAvatar
                                    }
                                    alt="avatar"
                                />
                            ) : (
                                <></>
                            )}
                            {tab === "guarantor" && requestGuarantor ? (
                                <img
                                    src={
                                        requestGuarantorProfileAvatar
                                            ? requestGuarantorProfileAvatar
                                            : DefaultAvatar
                                    }
                                    alt="avatar"
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="z-width-64">
                            {tab === "user" && (
                                <div>
                                    {user && (
                                        <p className="z-modal-header">
                                            {user.firstname} {user.lastname}
                                        </p>
                                    )}
                                </div>
                            )}

                            {tab === "guarantor" && (
                                <div>
                                    {requestGuarantorUser ? (
                                        <p className="z-modal-header">
                                            {requestGuarantorUser.firstname}{" "}
                                            {requestGuarantorUser.lastname}
                                        </p>
                                    ) : (<p className="z-modal-header">
                                        {requestGuarantor.guarantor_firstname}{" "}
                                        {requestGuarantor.guarantor_lastname}
                                    </p>)}
                                </div>
                            )}
                            {tab === "user" && (
                                <div className="z-width-100 z-ml-2 z-mt-8">
                                    <div className="z-flex-start">
                                        <div className="z-modal-left">
                                            <p>Date applied </p>
                                            <p>Amount Requested</p>
                                            <p>Current Monthly Salary</p>
                                            <p>Current Level</p>
                                            <p>Company</p>
                                            <p>Repayment Option</p>
                                            <p>Repayment Option Status</p>
                                        </div>
                                        <div className="z-modal-right z-ml-8">
                                            {loanRequest && (
                                                <>
                                                    {" "}
                                                    <p>
                                                        {moment(
                                                            loanRequest.created_at
                                                        ).format(
                                                            "Do MMMM YYYY"
                                                        )}
                                                    </p>
                                                    <p>
                                                        {naira}{" "}
                                                        {loanPackageAmount &&
                                                            formatCurrency(
                                                                loanPackageAmount
                                                            )}
                                                    </p>
                                                    <p>
                                                        {naira}{" "}
                                                        {formatCurrency(
                                                            loanRequest.current_salary
                                                        )}
                                                    </p>
                                                    <p>
                                                        {loanRequest.company_level &&
                                                            loanRequest
                                                                .company_level
                                                                .name}
                                                    </p>
                                                    <p>
                                                        {company &&
                                                            company.name}
                                                    </p>
                                                    <p>
                                                        {
                                                            loanRequest.repayment_option
                                                        }
                                                    </p>
                                                    <p>
                                                        {repaymentOptionStatus}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {tab === "user_bvn" && (
                                <div className="z-width-100 z-ml-2 z-mt-8">
                                    <div className="z-flex-start">
                                        {identityVerification ? (
                                            <>
                                                <div className="z-modal-left">
                                                    <p>BVN</p>
                                                    <p>First Name</p>
                                                    <p>Last Name</p>
                                                    <p>Middle Name</p>
                                                    <p>Date of Birth</p>
                                                    <p>Gender</p>
                                                </div>
                                                <div className="z-modal-right z-ml-8">
                                                    <p>
                                                        {
                                                            identityVerification.bvn
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            identityVerification.first_name
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            identityVerification.last_name
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            identityVerification.middle_name
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            identityVerification.date_of_birth
                                                        }
                                                    </p>
                                                    <p>
                                                        {
                                                            identityVerification.gender
                                                        }
                                                    </p>
                                                </div>{" "}
                                            </>
                                        ) : (
                                            "BVN Not Verified"
                                        )}
                                    </div>
                                </div>
                            )}

                            {tab === "guarantor" ? (
                                requestGuarantor ? (
                                    <div className="z-width-100 z-ml-2 z-mt-8">
                                        <div className="z-flex-start">
                                            <div className="z-modal-left">
                                                <p>Email </p>
                                                <p>Monthly Salary</p>
                                                <p>Approved Date</p>
                                                <p>Status</p>
                                            </div>
                                            <div className="z-modal-right z-ml-8">
                                                <p>
                                                    {
                                                        requestGuarantor.guarantor_email
                                                    }
                                                </p>
                                                <p>
                                                    {naira}{" "}
                                                    {formatCurrency(
                                                        requestGuarantor.guarantor_salary
                                                    )}
                                                </p>
                                                <p>
                                                    {requestGuarantor.guarantor_approved ===
                                                    "pending"
                                                        ? "Pending Approval"
                                                        : requestGuarantor.guarantor_approved &&
                                                          moment(
                                                              requestGuarantor
                                                                  .guarantor_approved
                                                                  .updated_at
                                                          ).format(
                                                              "Do MMMM YYYY"
                                                          )}
                                                </p>
                                                <p>
                                                    {
                                                        requestGuarantor.guarantor_approved
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    "No guarantor details found"
                                )
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </Row>
            </div>
        </Modal>
    );
}
